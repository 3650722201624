import React from 'react';
import { NavLink } from 'react-router-dom';
import { GrMoney } from 'react-icons/gr';
import { LuWarehouse, LuLogOut } from 'react-icons/lu';
import { CgClose } from 'react-icons/cg';
import { TbPackage } from "react-icons/tb";
import logo from '../assets/sbclogo.png';
import { MdDeliveryDining } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { BsBoxes } from "react-icons/bs";
import { RiBankCardLine } from "react-icons/ri";
import { subDirectory } from '../utils/baseConfig';

const Sidebar = ({ isOpen, setIsSidebarOpen }) => {
  const menuItems = [
    { name: 'Dashboard', path: `${subDirectory}/home`, icon: <LuLayoutDashboard size={20} /> },
    { name: 'Orders', path: `${subDirectory}/orders-management`, icon: <BsBoxes size={20} /> },
    { name: 'Warehouse', path: `${subDirectory}/warehouse-management`, icon: <LuWarehouse size={20} /> },
    { name: 'Cash Route Sales', path: `${subDirectory}/cash-route-sales`, icon: <GrMoney size={20} /> },
    { name: 'Warehouse Sales', path: `${subDirectory}/warehouse-sales`, icon: <TbPackage size={20} /> },
    { name: 'Account', path: `${subDirectory}/account`, icon: <RiBankCardLine size={20} /> },
    { name: 'Drivers', path: `${subDirectory}/drivers-management`, icon: <MdDeliveryDining size={20} /> },
    { name: 'Logout', path: `${subDirectory}/logout`, icon: <LuLogOut size={20} /> },
  ];

  return (
    <div className={`h-full w-64 bg-white text-gray-800 transform transition-transform duration-300 ease-in-out
      ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
      <div className="flex flex-col gap-5 p-4">
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="absolute top-0 right-0 p-4 block md:hidden"
        >
          <CgClose />
        </button>
        <div className="flex gap-2 items-end mb-8">
          <img src={logo} alt="logo" className="w-12 h-16" />
          <h1 className="text-xl font-new-amsterdam font-medium">Customer Trade Portal</h1>
        </div>
        <ul className='mt-5'>
          {menuItems.map((item) => (
            <li key={item.name} className="mb-4">
              <NavLink
                to={item.path}
                onClick={() => setIsSidebarOpen(false)}
                className={({ isActive }) =>
                  `flex items-center px-2 py-3 rounded hover:bg-accent hover:bg-opacity-30 hover:text-accent transition ${isActive ? 'bg-accent text-white' : 'text-gray-700'}`
                }
              >
                {item.icon}
                <span className="ml-4">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;