import React, { useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../context/AuthContext';
import { AxiosConfig } from '../utils/baseConfig';
import logo from '../assets/sbclogo.png';
import { ToastContainer, toast } from 'react-toastify';
import { ImEyeBlocked, ImEye } from "react-icons/im";
import 'react-toastify/dist/ReactToastify.css';
import { LoaderButton } from '../components/LoaderButton';
import loginVid from '../assets/loginVid.mp4';
import { subDirectory } from '../utils/baseConfig';
// Define the validation schema with Yup
const LoginSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone Number is required'),
  password: Yup.string().required('Password is required'),
  rememberMe: Yup.boolean(),
});

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const rememberedPhoneNumber = localStorage.getItem('rememberedPhoneNumber') || '';

  const sendLoginRequest = async (values, { resetForm }) => {
    setIsLoading(true);
    const payload = {
      password: btoa(values.password),
      username: btoa(values.phoneNumber),
      channelId: 1,
    };

    try {
      const res = await AxiosConfig.post('/DigitalRetail/Login', payload);
      if (res.data.data) {
        const details = res.data.data;
        console.log(details);
        localStorage.setItem('userDetails', JSON.stringify(details));
        const userDetails = {
          userId: details.autoId,
          firstName: details.firstName,
          lastName: details.lastName,
          roleId: details.roleId,
          roleName: details.roleName,
          token: res.data.jwtToken,
          externalId: details.externalId,
          customerCode: details.sbcCustomerCode,
        };

        if (values.rememberMe) {
          localStorage.setItem('rememberedPhoneNumber', values.phoneNumber);
        } else {
          localStorage.removeItem('rememberedPhoneNumber');
        }

        login(userDetails);
        toast.success("Login Successful");
        setTimeout(() => {
          window.location.href = `${subDirectory}/home`;
        }, 3000);
      } else {
        toast.error("Invalid Phone Number and Password");
        resetForm();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center h-screen">

      <div className="flex-1 w-full h-full bg-accent">
        <video className="w-full h-full object-cover object-left " autoPlay muted loop
          style={{
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <source src={loginVid} type="video/mp4" />
        </video>
      </div>
      <div className="flex-[2] md:flex-1 w-full flex flex-col p-4">

        <div className="flex justify-center items-center">
          <img src={logo} alt="logo" className="w-16 md:w-24 h-auto" />
        </div>

        <h1 className="text-2xl font-semibold tracking-wider font-new-amsterdam text-center mt-4">
          Customer Trade Management
        </h1>
        <h2 className="text-center mt-2">Welcome, please login to continue</h2>

        <Formik
          initialValues={{ phoneNumber: rememberedPhoneNumber, password: '', rememberMe: !!rememberedPhoneNumber }}
          validationSchema={LoginSchema}
          onSubmit={sendLoginRequest}
        >
          {({ isSubmitting }) => (
            <Form className="w-full md:w-3/5 mx-auto mt-8">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="phoneNumber">
                  Phone Number
                </label>
                <Field
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  className=" appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="phoneNumber" component="span" className="text-red-500 text-xs italic" />
              </div>
              <div className="mb-1">
                <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="password">
                  Password
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className=" appearance-none border rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"

                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="absolute right-0 mr-4 mt-4 cursor-pointer text-accent">
                    {showPassword ? <ImEyeBlocked /> : <ImEye />}
                  </span>
                </div>
                <ErrorMessage name="password" component="span" className="text-red-500 text-xs italic" />
              </div>
              <div className="mb-6 flex items-center">
                <Field type="checkbox" name="rememberMe" id="rememberMe" className="mr-2 w-3 h-3" />
                <label htmlFor="rememberMe" className="text-gray-700 text-sm">
                  Remember Me
                </label>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  className="bg-accent-light w-full py-3 hover:bg-accent-dark text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {isLoading ? <LoaderButton /> : 'Login'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Login;