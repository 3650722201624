import React from "react";
import {ScaleLoader} from 'react-spinners';

export const LoaderButton = () => {
    return (
        <>
            <ScaleLoader color="#fff" className="loader" loading={true} height={35} width={4} radius={2} margin={2} />
        </>
    );
    }
