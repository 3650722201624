import React, {useContext} from 'react';
import { BiSun, BiMoon, BiNotification } from 'react-icons/bi';
import { IoNotificationsOutline } from "react-icons/io5";
import { FilterContext } from '../context/DashboardContext';
const Header = ({ theme, toggleTheme, toggleSidebar }) => {
  const { notification} = useContext(FilterContext);
  

  // get user details from session storage
  const user = JSON.parse(sessionStorage.getItem('userDetails'));
  const userName = user ? user.firstName : 'User';
  const fullName = user ? `${user.firstName} ${user.lastName}` : 'User';
  const greeting = () => {
    const time = new Date().getHours();
    if (time < 12) {
      return 'Good Morning';
    } else if (time < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }

  const avatarInitials = fullName.split(' ').map(name => name[0]).join('');


  return (
    <header className={` p-4 flex justify-between items- bg-white md:bg-transparent`}>
    <div className="flex items-center gap-5">
      <button onClick={toggleSidebar} className="block md:hidden text-accent">
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>

      <div className="md:flex items-center gap-2 hidden">
        <h1 className="text-lg font-medium text-black">{greeting()}, {userName}</h1>
      </div>
    </div>

      <div className="flex items-center gap-5">
      {/* <button onClick={toggleTheme} className={`w-10 h-10 text-white bg-secondary-${theme} px-3 py-1 rounded-full`}>
        {theme === 'light' ? <BiMoon /> : <BiSun />}
      </button> */}
      <button className="relative flex justify-center items-center w-10 h-10 bg-secondary text-white px-3 py-1 rounded-full">
        <IoNotificationsOutline size={24} />
        <span className="absolute top-1 right-1 font-semibold w-4 h-4 text-accent-dark bg-white text-[12px] rounded-full flex items-center justify-center">
          {notification}
        </span>
      </button>
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 bg-accent text-white flex items-center justify-center rounded-full">
          {avatarInitials}
        </div>
        <p className="text-accent hidden md:block">{fullName}</p>
      </div>
      </div>
    </header>
  );
};

export default Header;
