import React from 'react'
import logoutVid from '../assets/curious-animate.svg'
import { subDirectory } from '../utils/baseConfig'
const Logout = () => {
  return (
    <div className="p-2 flex flex-col justify-center items-center gap-4 w-full min-h-screen">
      <div className="w-4/5 h-[70vh] bg-[#fff] flex flex-col justify-center items-center gap-2">

        <img src={logoutVid} alt="logout" className="w-[300px]" />
        <h1 className="text-2xl font-semibold">Log Out</h1>
        <p>Do you really want to log out?</p>

        <div className="flex gap-4 mb-2">
          <button
            className="bg-accent text-white px-4 py-2 rounded-md"
            onClick={() => window.history.back()}
          >
            Stay Logged In
          </button>
          <button
            className="border border-secondary text-secondary px-4 py-2 rounded-md hover:bg-secondary hover:text-white transition-colors duration-200"
            onClick={() => {
              localStorage.removeItem('user');
              window.location.href = `${subDirectory}/login`
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>

  )
}

export default Logout