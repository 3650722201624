import React from 'react'
import notfoundImg from '../assets/404-error.svg'
import {useNavigate} from 'react-router-dom'
const NotFound = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(-1)
    }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
        <img src={notfoundImg} alt="404 Not Found" className="w-[400px]" />
        <h1 className="text-6xl font-semibold mt-8 font-new-amsterdam tracking-wider">404 Not Found</h1>
        <p className="text-lg text-center mt-1">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
        <button onClick={handleNavigate} className="bg-accent text-white px-4 py-2 rounded-lg mt-8">Go Back</button>
    </div>
  )
}

export default NotFound