import React from 'react';
import {ScaleLoader} from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
        <ScaleLoader color="#059669" className="loader" loading={true} height={35} width={4} radius={2} margin={2} />
    </div>
  );
};

export default LoadingSpinner;