import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import { FilterProvider } from './context/DashboardContext';
import Layout from './components/Layout';
import LoadingSpinner from './components/LoadingSpinner';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ProtectedRoute from './routes/ProtectedRoutes';
import NotFound from './pages/NotFound';
import { subDirectory } from './utils/baseConfig';

// Lazy load components
const Dashboard = lazy(() => import('./pages/Dashboard'));
const OrdersManagement = lazy(() => import('./pages/OrdersManagement'));
const WarehouseManagement = lazy(() => import('./pages/WarehouseManagement'));
const CashRouteSales = lazy(() => import('./pages/CashRouteSales'));
const DriversManagement = lazy(() => import('./pages/DriversManagement'));
const WarehouseSales = lazy(() => import('./pages/WarehouseSales'));
const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const DriverVehicleRegistration = lazy(() => import('./pages/DriverVehicleRegistration'));
const WarehousePointOfSale = lazy(() => import('./pages/WarehousePointOfSale'));
const AccountSettlement = lazy(() => import('./pages/AccountSettlement'));
const LoadTruck = lazy(() => import('./pages/LoadTruck'));

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <FilterProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path={`${subDirectory}/login`} element={<Login />} />
              <Route path={`${subDirectory}/logout`} element={<Logout />} />
              <Route element={<Layout />}>
                <Route path={`${subDirectory}/home`} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path={`${subDirectory}/orders-management`} element={<ProtectedRoute><OrdersManagement /></ProtectedRoute>} />
                <Route path={`${subDirectory}/orders-management/order-details/:orderId`} element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
                <Route path={`${subDirectory}/warehouse-management`} element={<ProtectedRoute><WarehouseManagement /></ProtectedRoute>} />
                <Route path={`${subDirectory}/cash-route-sales`} element={<ProtectedRoute><CashRouteSales /></ProtectedRoute>} />
                <Route path={`${subDirectory}/cash-route-sales/load-truck`} element={<ProtectedRoute><LoadTruck /></ProtectedRoute>} />
                <Route path={`${subDirectory}/drivers-management`} element={<ProtectedRoute><DriversManagement /></ProtectedRoute>} />
                <Route path={`${subDirectory}/drivers-management/register-driver`} element={<ProtectedRoute><DriverVehicleRegistration /></ProtectedRoute>} />
                <Route path={`${subDirectory}/warehouse-sales`} element={<ProtectedRoute><WarehouseSales /></ProtectedRoute>} />
                <Route path={`${subDirectory}/warehouse-sales/point-of-sale`} element={<ProtectedRoute><WarehousePointOfSale /></ProtectedRoute>} />
                <Route path={`${subDirectory}/account`} element={<ProtectedRoute><AccountSettlement /></ProtectedRoute>} />
              </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </FilterProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;