import axios from 'axios';
// const baseUrl = "https://drs.sevenup.org";
const baseUrl = "https://drsdev.sevenup.org";
const subDirectory = "/ctm";
const AxiosFormConfig = axios.create({
  baseURL:baseUrl,
  headers: {'Content-Type': 'multipart/form-data'},
})

const AxiosConfig = axios.create({
  baseURL:baseUrl,
  headers: {'Accept': 'application/json'},
});


export{AxiosConfig, AxiosFormConfig, subDirectory} 