import React, { createContext, useState, useEffect } from 'react';
import { AxiosConfig } from '../utils/baseConfig';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [pendingItems, setPendingItems] = useState([]);
  const [intransitData, setIntransitData] = useState([]);
  const [updateTime, setUpdateTime] = useState(null);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const supplierExternalId = userDetails?.externalId;
  const customerCode = userDetails?.customerCode;
  const getDashboardData = async (filter) => {
    try {
      const response = await AxiosConfig.get(`/DigitalRetail/Orders/GetDashboardParams?supplierId=${supplierExternalId}&channelId=1&filter=4`);
      if (response.data.data) {
        setDashboardData(response.data.data);
        setNotification(response.data.data.unconfirmedOrders);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPendingOrders = async (filter) => {
    setLoading(true); // Set loading to true before data fetching
    try {
      const res = await AxiosConfig.get(`/DigitalRetail/Orders/GetAllOrdersBySupplierId?statusId=1&supplierId=${supplierExternalId}&channelId=1&filter=4`);
      if (res.data.data) {
        const sortedData = res.data.data.sort((a, b) => a.orderId.localeCompare(b.orderId));
        setPendingItems(sortedData);
      }
    } catch (err) {
      console.error(err);
    }

    try {
      const res = await AxiosConfig.get(`/DigitalRetail/Orders/GetAllOrdersBySupplierId?statusId=9&supplierId=${supplierExternalId}&channelId=1&filter=4`);
      if (res.data.data) {
        const sortedData = res.data.data.sort((a, b) => a.orderId.localeCompare(b.orderId));
        setIntransitData(sortedData);
      }
    } catch (err) {
      console.error(err);
    }

    await getDashboardData(filter);
    setLoading(false); // Set loading to false after data fetching is complete
  };

  useEffect(() => {
    if (updateTime === null) {
      setUpdateTime(new Date());
      getPendingOrders('');
    }
    const interval = setInterval(() => {
      getPendingOrders('');
      setUpdateTime(new Date());
    }, 3 * 60 * 1000);

    return () => clearInterval(interval);
  }, [updateTime]);

  return (
    <FilterContext.Provider value={{ dashboardData, pendingItems, intransitData, getPendingOrders, loading, notification }}>
      {children}
    </FilterContext.Provider>
  );
};
