import React, { useContext, useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { ThemeContext } from '../context/ThemeContext';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`bg-background-${theme} text-text-${theme}`}>
      <div className="flex md:flex-row min-h-screen relative">
        <div className={`fixed top-0 left-0 h-full z-50 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'w-64' : 'w-0'}`}>
          <Sidebar isOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        </div>
        <div className={` bg-background flex flex-col w-full md:ml-64 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
          <Header theme={theme} toggleTheme={toggleTheme} toggleSidebar={toggleSidebar} />
          <main className=" flex-1 p-2 md:p-4 overflow-auto transition-all duration-300">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
